import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  IconButton,
  Box,
  createStyles,
  makeStyles,
  Theme,
  InputAdornment,
} from '@material-ui/core';
import Markdown from 'react-markdown'
import './Copilot.css'
import SendIcon from '@material-ui/icons/Send';
import {
  CopilotAction,
  CopilotResponse,
  ExpensesFormType,
} from '../interfaces/Interfaces';
import API from '../utils/API';
import { AuthContext } from '../App';
import { blue } from '@material-ui/core/colors';


enum Role {
  human = "user",
  ai = "assistant"
}

export interface Message {
  content: string;
  role: Role;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      position: "relative",
      height: "calc(100vh - 112px)",
      overflow: "hidden",
    },
    messageContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignContent: "flex-end",
      overflow: "auto",
    },
    message: {
      borderRadius: "5px",
      padding: "7px",
      margin: "5px",
      wordWrap: "break-word",
      transition: "ease in-out 1s",
      "& p": {
        margin: 0,
      }
    },
    messageBuffer: {
      flexGrow: 1
    },
    humanMessage: {
      marginLeft: "15px",
      backgroundColor: theme.palette.primary.main,
    },
    AIMessage: {
      marginRight: "15px",
      backgroundColor: blue[800],
    },
    loadingMessage: {
      display: "flex",
      justifyContent: "center"
    },
    input: {
      bottom: 0,
      "& .MuiOutlinedInput-adornedEnd": {
        padding: 0
      },
      "& .MuiOutlinedInput-input": {
        padding: "8px 0 8px 8px"
      },
      "& .MuiTextField-root": {
        minWidth: "calc(100% - 8px)"
      }
    }
  })
)


export default function Copilot(props: {
  setLoading: Function;
  setOpen: Dispatch<SetStateAction<boolean>>;
  classes: { root: string; formControl: string };
  fillExpensesData: (e: ExpensesFormType) => void;
}) {
  const { Auth, setAlertState } = React.useContext(AuthContext);
  const [command, setCommand] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([])
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false)

  const classes = useStyles()

  const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
  recognition.lang = 'en-US';
  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    recognition.stop();
    setCommand(transcript);
  };
  // recognition.onspeechend = () => {
  //   setTimeout(() => submitCommand(), 300);
  // }

  useEffect(() => {
    setMessages([ { content: 'How can I help you?', role: Role.ai}]);
    startListening();
    return () => {
      recognition.stop();
    }
  }, [])

  function startListening() {
    recognition.start();
  }

  function handleInputUpdate(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    recognition.stop(); // Stop listening when user is typing
    setCommand(event.target.value);
  }

  async function submitCommand(event?: React.SyntheticEvent) {
    event?.preventDefault();
    setTimeout(() => setShowLoadingMessage(true), 250);
    setMessages([ ...messages, { content: command, role: Role.human } ])
    messages.push({ content: command, role: Role.human }) // Hacking around the fact that useState is asynchronous
    setCommand('')
    try {
      const messagesCopy = [...messages];
      const response = await API.copilot(messagesCopy, Auth.token);
      var tempMessage = '';
      let parsedValue: CopilotResponse;
      let decoder = new TextDecoderStream();
      if (!response.body) return;
      const reader = response.body
      .pipeThrough(decoder)
      .getReader();
      
      while (true) {
        var {value, done} = await reader.read();

        if (done) {
          break;
        }
        if (setShowLoadingMessage) {
          setShowLoadingMessage(false);
        }
        const splitValue = value.split(/(?<=\})(?=\{)/)
        for (let v of splitValue) {
          if (v === '') continue;
          parsedValue = JSON.parse(v);
          if (parsedValue.action === CopilotAction.logExpense) {
            const data: ExpensesFormType = JSON.parse(parsedValue.data);
            props.fillExpensesData(data);
            props.setOpen(false);
            return;
          }
          tempMessage += parsedValue.message;
          setMessages([ ...messagesCopy, { content: tempMessage, role: Role.ai } ])
        }
      }

      } catch (error: any) {
        console.log(error);
        setAlertState({
          severity: 'error',
          message: 'Error!',
          open: true,
        });
    } finally {
      setShowLoadingMessage(false);
      startListening();
    }
  }

  return (
    <Box className={classes.box}>
      <Typography variant="h5" component="h5" className={props.classes.root}>
        Copilot
      </Typography>
      <Box className={classes.messageContainer}>
        <div className={classes.messageBuffer}/>
        { messages.map(({ content, role }, i) => (
          <Box className={`${classes.message} ${role === Role.human ? classes.humanMessage : classes.AIMessage}`} key={i}>
            <Markdown>{content}</Markdown>
          </Box>
        )) }
        {showLoadingMessage ?
          <Box className={`${classes.message} ${classes.AIMessage} ${classes.loadingMessage}`}>
            <div className='loaderMessage'/>
          </Box>
        : null}
      </Box>
      <form onSubmit={submitCommand} style={{ display: "flex" }}>
        <TextField
          className={`${props.classes.formControl} ${classes.input}`}
          multiline={true}
          onChange={handleInputUpdate}
          value={command}
          variant="outlined"
          InputProps={{
            endAdornment: 
            <InputAdornment position="end">
                <IconButton type="submit" color="primary">
                  <SendIcon />
                </IconButton>
              </InputAdornment>,
          }}
        />
      </form>
    </Box>
  );
}
